import React from "react";
import { graphql, PageProps } from "gatsby";

import { PageLayout } from "src/components/layout/PageLayout";
import { Wysiwyg } from "src/components/Wysiwyg";

interface TermsConditionsPageProps extends PageProps {
  data: GatsbyTypes.TermsConditionsPageQuery;
}

const TermsConditionsPage: React.FC<TermsConditionsPageProps> = ({ data }) => {
  const pageContent = data.strapiTermsAndConditions?.content;

  return (
    <PageLayout title="Terms &amp; Conditions" width="normal">
      {pageContent && (
        <Wysiwyg content={pageContent} />
      )}
    </PageLayout>
  );
};

export default TermsConditionsPage;

export const query = graphql`
  query TermsConditionsPage {
    strapiTermsAndConditions {
      content
    }
  }
`;
